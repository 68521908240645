<nav class="header__links" [ngSwitch]="location" fxLayout="row" fxLayoutAlign="start center">
  <span class="mat-body-1">
    <ng-container *ngSwitchCase="'teacher'" i18n>Welcome {{ user.firstName }}!</ng-container>
    <ng-container *ngSwitchCase="'student'" i18n>Welcome {{ user.firstName }}!</ng-container>
  </span>
  <ng-container *ngSwitchDefault>
    <div>
      <a
        class="header-link mat-body-1"
        routerLink=""
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        i18n
        >Teachers</a
      >
      <a
        class="header-link mat-body-1"
        routerLink=""
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        i18n
        >Students</a
      >
      <a
        class="header-link mat-body-1"
        routerLink=""
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        i18n
        >Researchers</a
      >
      <a
        class="header-link mat-body-1"
        routerLink=""
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        [matMenuTriggerFor]="iSparkleMenu"
      >
        <span class="menu-link" fxLayoutAlign="start center" i18n
          >i-sparkle <mat-icon>arrow_drop_down</mat-icon></span
        >
      </a>
      <mat-menu #iSparkleMenu="matMenu" xPosition="before">
        <a
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          mat-menu-item
          routerLink=""
          i18n
        >
          Mission
        </a>
        <a
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          mat-menu-item
          routerLink=""
          i18n
        >
          Partnership
        </a>
        <a
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          mat-menu-item
          routerLink=""
          i18n
        >
          Curriculum
        </a>
        <a
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          mat-menu-item
          routerLink=""
          i18n
        >
          Tech
        </a>
        <a
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          mat-menu-item
          routerLink=""
          i18n
        >
          Impact
        </a>
      </mat-menu>
    </div>
  </ng-container>
  <ng-container *ngIf="!roles.length">
    <div fxLayout="row" fxLayoutGap="8px">
      &nbsp;|&nbsp;&nbsp;<app-header-signin></app-header-signin>
    </div>
  </ng-container>
</nav>
