<div class="main content" fxLayout="column" fxLayoutAlign="center center">
  <div class="notice container-bg mat-subtitle-1">
    <p i18n>
      The i-sparkle mission is to combine the power of teachers and technology to transform learning
      spaces and unlock student potential.
    </p>
    <p i18n>Learn more, get engaged, and spread the sparkle!</p>
  </div>
</div>
<div class="main content" fxLayout="column" fxLayoutAlign="center center">
  <h1 i18n>The i-sparkle approach</h1>
  <div class="notice container-bg mat-subtitle-1">
    <p i18n>
      <span class="sparkle-text" [@bounceIn]="{ value: '*', params: { delay: '0ms' } }">
        interactive</span
      >
      <span class="sparkle-text" [@bounceIn]="{ value: '*', params: { delay: '600ms' } }">
        sensemaking</span
      >&nbsp;<span class="mat-body-2">in a</span>&nbsp;<span
        class="sparkle-text"
        [@bounceIn]="{ value: '*', params: { delay: '1200ms' } }"
        >personalized</span
      >
      <span class="mat-body-2">, </span>
      <span class="sparkle-text" [@bounceIn]="{ value: '*', params: { delay: '1800ms' } }">
        adaptive</span
      ><span class="mat-body-2">, </span>
      <span class="sparkle-text" [@bounceIn]="{ value: '*', params: { delay: '2400ms' } }">
        responsive</span
      >
      <span class="mat-body-2">, and</span>
      <span class="sparkle-text" [@bounceIn]="{ value: '*', params: { delay: '3000ms' } }">
        kind</span
      >
      <span class="sparkle-text" [@bounceIn]="{ value: '*', params: { delay: '3600ms' } }">
        learning environment
      </span>
    </p>
  </div>
</div>
<div class="main content">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="center stretch"
    fxLayoutAlign.xs="center center"
    fxLayoutGap="32px"
    fxLayoutGap.sm="24px"
    fxLayoutGap.gt-sm="32px"
  >
    <app-call-to-action
      fxFlex
      icon="feed"
      iconColor="accent-2"
      i18n-headline
      headline="Tutorials"
      destination=""
    ></app-call-to-action>
    <app-call-to-action
      fxFlex
      icon="help"
      iconColor="accent"
      i18n-headline
      headline="FAQ"
      destination=""
    ></app-call-to-action>
    <app-call-to-action
      fxFlex
      icon="info"
      iconColor="accent"
      i18n-headline
      headline="About Us"
      destination=""
    ></app-call-to-action>
    <app-call-to-action
      fxFlex
      icon="contact_mail"
      iconColor="accent"
      i18n-headline
      headline="Get in Touch"
      destination="/contact"
    ></app-call-to-action>
  </div>
</div>
<div class="main content" fxLayout="column" fxLayoutAlign="center center">
  <div class="notice container-bg mat-subtitle-1">
    <p i18n>Wondering if i-sparkle is for you?</p>
    <p i18n>
      Find out how the free and open-source i-sparkle technology benefits your
      <a routerLink="">school</a>, <a routerLink="">teaching</a>, or <a routerLink="">research</a>!
    </p>
  </div>
</div>
<div class="main content center" fxLayoutAlign="center center" fxLayoutGap="8px">
  <span class="mat-headline-5" i18n>Ready to use i-sparkle?</span>
  <a mat-flat-button color="primary" class="mat-subtitle-1" routerLink="join">Sign up for free!</a>
</div>
