<mat-form-field class="search-bar" appearance="fill" (click)="$event.stopPropagation()">
  <mat-label>{{ placeholderText }}</mat-label>
  <mat-icon matPrefix>search</mat-icon>
  <input matInput [formControl]="searchField" (keydown)="$event.stopPropagation()" />
  <button
    mat-icon-button
    matSuffix
    [disabled]="disable"
    [ngClass]="{ invisible: !searchField.value }"
    i18n-aria-label
    aria-label="Clear"
    (click)="clear()"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
